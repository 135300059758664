
import Vue from "vue"

// Local
import { Tokens } from "@/database"
import { devOnlyError } from "@/logging"
import LoadingClipboardIcon from "@/components/LoadingClipboardIcon.vue"
import {
  DEV_REACT_APP_AUTO_LOGIN_URL,
  PROD_REACT_APP_AUTO_LOGIN_URL,
  VIEW_ESTIMATE_PATH,
} from "@/constants"

function viewEstimateAutoLoginURL(
  tokenKey: string,
  estimateID: string
): string {
  const firebaseProject = process.env.VUE_APP_FIREBASE_PROJECT
  if (firebaseProject === "scaffoldtracker") {
    return `${PROD_REACT_APP_AUTO_LOGIN_URL}?tokenKey=${tokenKey}&destination=${VIEW_ESTIMATE_PATH}/${estimateID}`
  } else if (firebaseProject === "stilt-dev") {
    return `${DEV_REACT_APP_AUTO_LOGIN_URL}?tokenKey=${tokenKey}&destination=${VIEW_ESTIMATE_PATH}/${estimateID}`
  } else {
    throw Error("Invalid Firebase project setting in View Estimate IFrame")
  }
}

export default Vue.extend({
  name: "ViewEstimateIFrame",
  components: {
    LoadingClipboardIcon,
  },
  data() {
    return {
      tokenKey: null,
      iframeSource: "/404.html",
      loading: true, // Set to true to show loading icon
    }
  },
  beforeMount() {
    Tokens.createCustomTokenKey().then((tokenKey) => {
      if (typeof tokenKey === "string") {
        this.loading = false
        this.tokenKey = tokenKey
        const estimateID = this.$route.params.estimateID
        this.iframeSource = viewEstimateAutoLoginURL(this.tokenKey, estimateID)
      } else {
        devOnlyError(
          "Unable to automatically log into the view estimate module."
        )
      }
    })
  },
})
